import React from 'react'
import { graphql } from 'gatsby'

import { msalConfig, loginRequest } from '../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

import classes from '../styles/course-info.module.sass'

import Wrapper from '../components/wrapper'



export default ({ data }) =>
{

    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                <div className={classes.container}>
                    <h1 className={classes.pagetitle}>{data.site.siteMetadata.i18n.pages.courseInfo}</h1>
                    <div className={classes.infocontainer}>
                        {data.site.siteMetadata.courseInfo.map((item, index) => (
                            <div className={classes.infotext}>
                                <h3 className={classes.sectiontitle}>{item.title}</h3>
                                <p className={classes.sectiontext}
                                    dangerouslySetInnerHTML={{__html: item.text}}>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </Wrapper>
        </MsalProvider>
    )
}

export const query = graphql`
  query CourseInfoQuery {
    site {
      siteMetadata {
        i18n {
          pages {
            courseInfo
          }
        }
        courseInfo {
          title
          text
        }
      }
    }
  }
`
